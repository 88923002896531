@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-family: system-ui;
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

li {
  padding: 0.5rem 0;
}

.test {
  z-index: 0;
}

.leftTextContainer {
  position: absolute;
  z-index: 100;
  top: 45%;
  left: 60%;
  transform: translate(-50%, -50%);
}

.rightTextContainer {
  position: absolute;
  top: 45%;
  left: 30%;
  transform: translate(-50%, -50%);
}

@media (max-width: 700px) {
  .leftTextContainer {
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  .rightTextContainer {
    left: 50%;
    transform: translateX(-50%);
  }
}
